import * as React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import {
    Container,
    Grid,
    MantineTheme,
    Space,
    Text,
    Title,
    useMantineTheme,
    Stack,
    Anchor,
    MediaQuery,
    List,
    createStyles
} from "@mantine/core";

// illustrations
import privacySVG from '../../images/illustrations/privacy.svg';
import ListSection from "../../components/sections/list-section";

// icons
import bulletIcon from '../../images/icons/bullet.svg';
import { ReactNode } from "react";

const useStyles = createStyles((theme: MantineTheme) => ({
    contentAlign: {
        [theme.fn.smallerThan('md')]: {
            textAlign: 'center',
            fontSize: "calc(32px + 1vw)",
            paddingLeft: theme.spacing.xl,
            paddingRight: theme.spacing.xl,
            lineHeight: 1.3
        }
    },
    listItems: {
        [theme.fn.smallerThan('md')]: {
            alignSelf: 'center',
        }
    },
    listFont: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(30px + 1vw)",
            lineHeight: 1.3
        }
    },
    qminoMailTag: {
        [theme.fn.smallerThan('md')]: {
            fontSize: "calc(32px + 1vw)",
        }
    }
}))

const PrivacyPage = () => {
    const { classes } = useStyles();
    const theme: MantineTheme = useMantineTheme();

    const persoonGegevens: ReactNode[] = [
        <Text className={classes.listFont} color={'gray.7'}>Voor- en achternaam</Text>,
        <Text className={classes.listFont} color={'gray.7'}>Adresgegevens</Text>,
        <Text className={classes.listFont} color={'gray.7'}>Telefoonnummer</Text>,
        <Text className={classes.listFont} color={'gray.7'}>E-mailadres</Text>,
        <Text className={classes.listFont} color={'gray.7'}>IP-adres</Text>
    ]

    const InfoMailto = () =>
        <Anchor
            className={classes.qminoMailTag}
            href="mailto:<InfoMailTo/>"
            target='_blank'
            color='brand.7'>
            <strong>hello@qmino.com</strong>
        </Anchor>
    return (
        <Layout>
            <Container py="5rem" size="lg">
                <Grid>
                    <Grid.Col offset={3} offsetSm={2.5} offsetMd={0} xs={12} sm={7}>
                        <Title order={1} my="xl">
                            <Text
                                size={40}
                                color="#444444"
                                sx={{
                                    lineHeight: 1.3,
                                    [theme.fn.smallerThan('md')]: {
                                        textAlign: 'center',
                                        fontSize: "calc(42px + 1vw)"
                                    }
                                }}>
                                Privacybeleid
                            </Text>
                        </Title>
                        <Space h="xl" />
                        <Stack spacing={"xl"}>
                            <Text size={20} color="gray.7" className={classes.contentAlign}>
                                Wij kunnen persoonsgegevens over u verwerken, doordat u gebruik maakt van onze diensten
                                en/of omdat u deze zelf bij het verzenden van een mail via onze website aan Qmino
                                verstrekt.
                            </Text>
                            <Text size={20} color="gray.7" className={classes.contentAlign}>
                                Wij kunnen volgende persoonsgegevens verwerken:
                            </Text>
                            <Text className={classes.listItems}>
                                <ListSection
                                    icon={
                                        <img
                                            src={bulletIcon}
                                            alt="bullet"
                                            width={18}
                                        />

                                    } items={persoonGegevens} />

                            </Text>
                        </Stack>
                    </Grid.Col>
                    <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
                        <Grid.Col offset={0} offsetMd={0} offsetSm={1} sm={2}>
                            <img
                                src={privacySVG}
                                alt={'disclaimer checklist'}
                                height={320}
                                style={{
                                    position: 'relative',
                                    top: '240px',
                                    left: '50px',
                                }}
                            />
                        </Grid.Col>
                    </MediaQuery>
                </Grid>
            </Container>
            <div style={{ backgroundColor: theme.colors.gray[1] }}>
                <Container py="5rem" size="lg">
                    <Grid>
                        <Grid.Col offset={0} offsetMd={0} xs={12} lg={10}>
                            <Stack spacing={"xl"} className={classes.contentAlign}>
                                <Stack spacing={"sm"}>
                                    <Title className={classes.contentAlign} order={2}>Waarom wij uw gegevens nodig hebben</Title>
                                    <Text className={classes.contentAlign}>
                                        Qmino verwerkt uw persoonsgegevens om u telefonisch te contacteren als u daar om vraagt, en/of om u schriftelijk
                                        (per e-mail en/of per post) te kunnen benaderen indien we u telefonisch niet kunnen bereiken.
                                    </Text>
                                    <Text className={classes.contentAlign}>
                                        Daarnaast kunnen wij uw persoonsgegevens gebruiken in het kader van het uitvoeren van een met u gesloten overeenkomst van opdracht,
                                        doorgaans bestaande uit IT dienstverlening, of om u op de hoogte te houden van onze activiteiten. Bovendien kunnen we uw persoonsgegevens gebruiken voor reclame- of marketingdoeleinden.
                                        In geval van gebruik van gegevens voor direct marketing, kan u zich hier kosteloos tegen verzetten door te mailen naar <InfoMailto></InfoMailto>.
                                    </Text>
                                </Stack>
                                <Stack spacing={"sm"}>
                                    <Title className={classes.contentAlign} order={2}>Hoe lang bewaren wij uw gegevens</Title>
                                    <Text className={classes.contentAlign}>
                                        Qmino bewaart uw persoonsgegevens niet langer dan strikt nodig om de doelen te realiseren, waarvoor uw gegevens worden verzameld.
                                        Uw gegevens worden niet langer dan een jaar bewaard indien er geen overeenkomst met u tot stand komt.
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Title className={classes.contentAlign} order={2}>Delen met anderen</Title>
                                    <Text className={classes.contentAlign}>
                                        Qmino verstrekt uw persoonsgegevens alléén aan derden indien dit nodig is voor de uitvoering van een overeenkomst met u,
                                        of om te voldoen aan een wettelijke verplichting.
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Title className={classes.contentAlign} order={2}>In kaart brengen websitebezoek</Title>
                                    <Text className={classes.contentAlign}>
                                        Op onze website worden algemene bezoekgegevens bijgehouden, waaronder het IP-adres van uw computer en het tijdstip van opvraging en gegevens die uw browser meestuurt.
                                        Deze gegevens worden gebruikt voor analyses van bezoek- en klikgedrag op de website.
                                        Qmino gebruikt deze informatie om de werking van de website te verbeteren. Deze gegevens worden zo veel mogelijk geanonimiseerd en worden niet aan derden verstrekt.
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Title className={classes.contentAlign} order={2}>Cookies</Title>
                                    <Text className={classes.contentAlign}>
                                        Hou er rekening mee dat we zogenaamde 'cookies' of gelijkaardige technologie kunnen gebruiken als deel van de website en/of de diensten.
                                        Cookies zijn kleine tekstbestanden die geplaatst worden op de harde schijf van een toestel en die bepaalde informatie bevatten, waaronder soms persoonsgegevens.
                                        Voor meer informatie betreffende ons gebruik van cookies, gelieve u onze cookie policy te lezen.
                                    </Text>
                                    <Title className={classes.contentAlign} order={2}>Gegevens inzien, aanpassen of verwijderen</Title>
                                    <Text className={classes.contentAlign}>
                                        U hebt het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. U kan een verzoek tot inzage, correctie of verwijdering sturen naar <InfoMailto></InfoMailto>. Om misbruik te voorkomen kunnen wij u daarbij vragen om uzelf adequaat te identificeren.
                                        Wanneer het gaat om inzage in persoonsgegevens gekoppeld aan een cookie, dient u een kopie van het cookie in kwestie mee te sturen.
                                        U kunt deze terugvinden in de instellingen van uw browser.
                                    </Text>
                                    <Title className={classes.contentAlign} order={2}>Beveiliging</Title>
                                    <Text className={classes.contentAlign}>
                                        Qmino neemt de bescherming van uw gegevens ernstig en neemt passende maatregelen om misbruik, verlies,
                                        onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
                                        Onze website maakt gebruik van een betrouwbaar SSL Certificaat om te borgen dat uw persoonsgegevens niet in verkeerde handen vallen.
                                        Als u de indruk hebt dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, of indien u meer informatie wenst over de beveiliging van door Qmino verzamelde persoonsgegevens,
                                        neem dan contact met ons op via <InfoMailto></InfoMailto>. www.qmino.com is een website van Qmino. U kan ons ook een schrijven bezorgen: Nieuwstraat 28, 3360 Korbeek-Lo.
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text className={classes.contentAlign}>
                                        Door gebruik te maken van onze website en/of onze diensten, erkent u dit privacybeleid te hebben gelezen en stemt u hier zonder voorbehoud mee in.
                                        U verklaart dat u begrijpt met welke doeleinden uw persoonsgegevens worden verwerkt. U stemt er ook mee in dat uw voortgezet gebruik wordt begrepen als een voortgezette toestemming.
                                        Uw toestemming kan u op elk ogenblik intrekken door te mailen naar <InfoMailto></InfoMailto>.
                                    </Text>

                                    <Text className={classes.contentAlign}>
                                        Wij behouden ons het recht om dit privacybeleid te wijzigen volgens ons eigen inzicht.
                                        Zulke wijziging zal via de website gecommuniceerd worden.
                                    </Text>

                                    <Text className={classes.contentAlign}>
                                        Dit privacybeleid werd laatst bijgewerkt op 9 oktober 2023.
                                    </Text>
                                </Stack>
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </Container>
            </div>
        </Layout>
    );
}

export const Head = () => (
    <SEO title='Privacybeleid' />
);

export default PrivacyPage;
